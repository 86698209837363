@font-face {
  font-family: 'bp4wp';
  src:  url('fonts/bp4wp.eot?2zer6p');
  src:  url('fonts/bp4wp.eot?2zer6p#iefix') format('embedded-opentype'),
    url('fonts/bp4wp.woff2?2zer6p') format('woff2'),
    url('fonts/bp4wp.ttf?2zer6p') format('truetype'),
    url('fonts/bp4wp.woff?2zer6p') format('woff'),
    url('fonts/bp4wp.svg?2zer6p#bp4wp') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="usd-"], [class*=" usd-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bp4wp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.usd-home:before {
  content: "\e925";
}
.usd-backup-child-care:before {
  content: "\e91f";
}
.usd-child-care-assistance:before {
  content: "\e920";
}
.usd-flexible-hours:before {
  content: "\e921";
}
.usd-onsite-child-care:before {
  content: "\e922";
}
.usd-paid-time-off:before {
  content: "\e923";
}
.usd-working-remotely:before {
  content: "\e924";
}
.usd-bars:before {
  content: "\e91e";
}
.usd-star:before {
  content: "\e91d";
}
.usd-arrow-down2:before {
  content: "\e910";
}
.usd-arrow-left2:before {
  content: "\e911";
}
.usd-arrow-right2:before {
  content: "\e912";
}
.usd-arrow-up2:before {
  content: "\e913";
}
.usd-breastfeeding:before {
  content: "\e914";
}
.usd-check:before {
  content: "\e915";
}
.usd-close:before {
  content: "\e916";
}
.usd-delete:before {
  content: "\e917";
}
.usd-download:before {
  content: "\e918";
}
.usd-maternity:before {
  content: "\e919";
}
.usd-medical:before {
  content: "\e91a";
}
.usd-paid-time:before {
  content: "\e91b";
}
.usd-thumbs-up:before {
  content: "\e91c";
}
.usd-eye-invisible:before {
  content: "\e90d";
}
.usd-password:before {
  content: "\e90e";
}
.usd-view-password:before {
  content: "\e90f";
}
.usd-applications:before {
  content: "\e900";
}
.usd-arrow-down:before {
  content: "\e901";
}
.usd-calendar:before {
  content: "\e902";
}
.usd-csv:before {
  content: "\e903";
}
.usd-dashboard:before {
  content: "\e904";
}
.usd-dots:before {
  content: "\e905";
}
.usd-logout:before {
  content: "\e906";
}
.usd-my-profile:before {
  content: "\e907";
}
.usd-questionnaire:before {
  content: "\e908";
}
.usd-remove:before {
  content: "\e909";
}
.usd-search:before {
  content: "\e90a";
}
.usd-upload:before {
  content: "\e90b";
}
.usd-warning:before {
  content: "\e90c";
}
